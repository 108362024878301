import React from "react";
import Meta from "./../components/Meta";
import Navbar from "./../components/Navbar";
import HeroSection from "./../components/HeroSection";
import Footer from "./../components/Footer";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="/logo.svg"
        logoInverted="/logo-white.svg"
      />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="STC Labs welcomes you on Earth"
        subtitle="Congratulations with happy landing on our planet. We are a small development studio of humans and a couple of cats. We are very proud to meet you with our humble introduction page here."
      />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} STC Labs`}
        logo="/logo-full.svg"
        logoInverted="/logo-white-full.svg"
        sticky={true}
      />
    </>
  );
}

export default IndexPage;
